import {apiRequest} from './base'

//我的群组列表
export function postGroupMyGroups(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/my-groups',req,onOk,onFail,eventually,headers)
}
//搜索我的共享组
export function postGroupSearchMyGroups(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/search-my-groups',req,onOk,onFail,eventually,headers)
}
//查找共享组
export function postGroupFindGroups(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/find-groups',req,onOk,onFail,eventually,headers)
}
//共享组详情
export function postGroupProfile(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/profile',req,onOk,onFail,eventually,headers)
}
//创建共享组
export function postGroupNew(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/new',req,onOk,onFail,eventually,headers)
}
//获取组标签
export function postGroupGetGtag(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/get-gtag',req,onOk,onFail,eventually,headers)
}
//获取推荐标签列表
export function postGroupRecommendGtags(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/recommend-gtags',req,onOk,onFail,eventually,headers)
}
//置顶我的共享组
export function postGroupSetTop(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/set-top',req,onOk,onFail,eventually,headers)
}
//取消置顶我的共享组
export function postGroupUnsetTop(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/unset-top',req,onOk,onFail,eventually,headers)
}
//举报共享组
export function postGroupReportGgroup(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/report-ggroup',req,onOk,onFail,eventually,headers)
}
//获取讨论列表
export function postGroupGcomments(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/gcomments',req,onOk,onFail,eventually,headers)
}
//列出系统组头像
export function postGroupListSystemGcovers(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/list-system-gcovers',req,onOk,onFail,eventually,headers)
}
//添加评论
export function postGroupAddGcomment(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/add-gcomment',req,onOk,onFail,eventually,headers)
}
//点赞评论
export function postGroupVoteGcomment(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/vote-gcomment',req,onOk,onFail,eventually,headers)
}
//取消评论点赞
export function postGroupUnvoteGcomment(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/unvote-gcomment',req,onOk,onFail,eventually,headers)
}
//删除评论
export function postGroupDeleteGcomment(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/delete-gcomment',req,onOk,onFail,eventually,headers)
}
//举报评论
export function postGroupReportGcomment(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/report-gcomment',req,onOk,onFail,eventually,headers)
}
//获取组内文件列表
export function postGroupListGfiles(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/list-gfiles',req,onOk,onFail,eventually,headers)
}
//列出组内文件夹
export function postGroupListGdirs(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/list-gdirs',req,onOk,onFail,eventually,headers)
}
//搜索思维导图/资料
export function postGroupSearchGfiles(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/search-gfiles',req,onOk,onFail,eventually,headers)
}
//导入导图到共享组
export function postGroupImportGmap(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/import-gmap',req,onOk,onFail,eventually,headers)
}
//新建文件夹
export function postGroupNewGdir(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/new-gdir',req,onOk,onFail,eventually,headers)
}
//文件置顶
export function postGroupSetTopGfile(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/set-top-gfile',req,onOk,onFail,eventually,headers)
}
//文件取消置顶
export function postGroupUnsetTopGfile(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/unset-top-gfile',req,onOk,onFail,eventually,headers)
}
//重命名
export function postGroupRenameGfile(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/rename-gfile',req,onOk,onFail,eventually,headers)
}
//创建导图
export function postGroupNewGmap(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/new-gmap',req,onOk,onFail,eventually,headers)
}
//上传资料文件
export function postGroupUploadGfile(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/upload-gfile',req,onOk,onFail,eventually,headers)
}
//查看资料的基本信息
export function postGroupViewGfile(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/view-gfile',req,onOk,onFail,eventually,headers)
}
//删除组内文件
export function postGroupDeleteGfile(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/delete-gfile',req,onOk,onFail,eventually,headers)
}
//移动文件
export function postGroupMoveGfile(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/move-gfile',req,onOk,onFail,eventually,headers)
}
//所有文件修改记录列表
export function postGroupGfileRecords(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/gfile-records',req,onOk,onFail,eventually,headers)
}
//单文件修改记录
export function postGroupGfileRecord(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/gfile-record',req,onOk,onFail,eventually,headers)
}
//存储空间使用情况
export function postGroupGstorageInfo(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/gstorage-info',req,onOk,onFail,eventually,headers)
}
//举报文件
export function postGroupReportGfile(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/report-gfile',req,onOk,onFail,eventually,headers)
}
//最近删除的组文件列表
export function postGroupDeletedGfiles(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/deleted-gfiles',req,onOk,onFail,eventually,headers)
}
//彻底删除组文件
export function postGroupPurgeGfiles(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/purge-gfiles',req,onOk,onFail,eventually,headers)
}
//恢复组文件
export function postGroupRestoreGfiles(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/restore-gfiles',req,onOk,onFail,eventually,headers)
}
//清空回收站
export function postGroupEmptyTrash(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/empty-trash',req,onOk,onFail,eventually,headers)
}
//查看导图
export function postGroupViewGmap(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/view-gmap',req,onOk,onFail,eventually,headers)
}
//导图修改记录
export function postGroupGmapRecords(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/gmap-records',req,onOk,onFail,eventually,headers)
}
//保存到我的导图
export function postGroupSaveGmap(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/save-gmap',req,onOk,onFail,eventually,headers)
}
//编辑导图
export function postGroupEditGmap(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/edit-gmap',req,onOk,onFail,eventually,headers)
}
//编辑导图保活ping请求
export function postGroupGmapEditPing(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/gmap-edit-ping',req,onOk,onFail,eventually,headers)
}
//结束编辑状态
export function postGroupGmapEditFinish(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/gmap-edit-finish',req,onOk,onFail,eventually,headers)
}
//同步组导图内容
export function postGroupUploadGmap(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/upload-gmap',req,onOk,onFail,eventually,headers)
}
//申请加入群组
export function postGroupJoin(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/join',req,onOk,onFail,eventually,headers)
}
//邀请用户加入组
export function postGroupInvite(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/invite',req,onOk,onFail,eventually,headers)
}
//查看群消息列表
export function postGroupGmessages(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/gmessages',req,onOk,onFail,eventually,headers)
}
//同意群消息
export function postGroupConfirmGmessage(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/confirm-gmessage',req,onOk,onFail,eventually,headers)
}
//驳回群消息
export function postGroupRejectGmessage(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/reject-gmessage',req,onOk,onFail,eventually,headers)
}
//查看全部群组成员
export function postGroupMembers(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/members',req,onOk,onFail,eventually,headers)
}
//搜索群成员
export function postGroupSearchGmembers(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/search-gmembers',req,onOk,onFail,eventually,headers)
}
//删除组成员
export function postGroupDeleteGmembers(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/delete-gmembers',req,onOk,onFail,eventually,headers)
}
//查询管理员列表
export function postGroupAdmins(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/admins',req,onOk,onFail,eventually,headers)
}
//取消成员的管理员权限
export function postGroupDeleteGadmins(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/delete-gadmins',req,onOk,onFail,eventually,headers)
}
//添加管理员
export function postGroupAddGadmin(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/add-gadmin',req,onOk,onFail,eventually,headers)
}
//所有非管理员列表
export function postGroupNonGadmins(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/non-gadmins',req,onOk,onFail,eventually,headers)
}
//组设置的基本信息
export function postGroupGsettings(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/gsettings',req,onOk,onFail,eventually,headers)
}
//组权限的信息
export function postGroupGpermissionSettings(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/gpermission-settings',req,onOk,onFail,eventually,headers)
}
//修改组权限设置信息
export function postGroupModifyGpermission(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/modify-gpermission',req,onOk,onFail,eventually,headers)
}
//修改共享组封面
export function postGroupChangeGcover(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/change-gcover',req,onOk,onFail,eventually,headers)
}
//修改共享组名
export function postGroupChangeName(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/change-name',req,onOk,onFail,eventually,headers)
}
//修改共享组介绍
export function postGroupChangeDesc(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/change-desc',req,onOk,onFail,eventually,headers)
}
//修改封面+组名+介绍
export function postGroupChangeInfo(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/change-info',req,onOk,onFail,eventually,headers)
}
//修改组标签
export function postGroupChangeTags(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/change-tags',req,onOk,onFail,eventually,headers)
}
//修改组公告
export function postGroupChangeNotice(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/change-notice',req,onOk,onFail,eventually,headers)
}
//解散共享组
export function postGroupDeleteGroup(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/delete-group',req,onOk,onFail,eventually,headers)
}
//退出共享组
export function postGroupLeaveGroup(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/leave-group',req,onOk,onFail,eventually,headers)
}
//新建分页
export function postGroupNewGsheet(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/new-gsheet',req,onOk,onFail,eventually,headers)
}
//删除分页
export function postGroupDeleteGsheet(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/delete-gsheet',req,onOk,onFail,eventually,headers)
}
//调整分页顺序
export function postGroupMoveGsheet(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/move-gsheet',req,onOk,onFail,eventually,headers)
}
//分页重命名
export function postGroupModifyGsheet(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/modify-gsheet',req,onOk,onFail,eventually,headers)
}
//复制分页
export function postGroupCopyGsheet(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/copy-gsheet',req,onOk,onFail,eventually,headers)
}
//拉取分页列表
export function postGroupListGsheets(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/list-gsheets',req,onOk,onFail,eventually,headers)
}
//搜索分页
export function postGroupSearchGsheet(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/group/search-gsheet',req,onOk,onFail,eventually,headers)
}